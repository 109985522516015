<template>
  <v-card>
    <v-card-title>Meal Bin/Rack Assignment Configuration</v-card-title>
    <v-card-text>
      <v-row
        class="my-1 justify-center align-center" style="border: 1px solid black" v-for="(rackConfig,index) of rackConfigs"
        v-bind:key="index">
        <v-col>
          {{ rackConfig.boxType }}
        </v-col>
        <v-col>
          <v-text-field type="number" v-model="rackConfig.maxRacks" label="How Many Racks"
                        @change="parseChange(rackConfig)"/>
        </v-col>
        <v-col>
          <v-text-field
            type="number" v-model="rackConfig.mealsPerBinMinimum" label="Minimum Meals Per Bin"
            @change="parseChange(rackConfig)"
            :max="rackConfig.mealsPerBin"
          />
        </v-col>
        <v-col>
          <v-text-field
            type="number" v-model="rackConfig.mealsPerBin" label="Maximum Meals Per Bin"
            @change="parseChange(rackConfig)"
            :min="rackConfig.mealsPerBinMinimum"
          />
        </v-col>
        <v-col>
          <v-text-field type="number" v-model="rackConfig.rowsOfBins" label="Rows of Bins"
                        @change="parseChange(rackConfig)"/>
        </v-col>
        <v-col>
          <v-text-field type="number" v-model="rackConfig.binsPerRow" label="Bins per Row"
                        @change="parseChange(rackConfig)"/>
        </v-col>
        <v-col>
          <v-select
            multiple chips v-model="rackConfig.locations" :items="['home','office']"
          label="Locations" @change="parseChange(rackConfig)"/>
        </v-col>
        <v-col>
          <v-btn
            @click="save(rackConfig)"
            :disabled="JSON.stringify(rackConfigsOriginal[index])===JSON.stringify(rackConfig)">
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>


<script>
import urlState from "@/router/urlState";
import api from "@/api";
import {deepCopy} from "@/store/utils";

export default {
  mixins: [urlState],
  data() {
    return {
      rackConfigs: [],
      rackConfigsOriginal: []
    }
  },
  async mounted() {
    await this.loadConfigs();

  },
  methods: {
    async loadConfigs() {
      this.rackConfigs = await api.get('v2/order/rack-configs').then(r => r.data);
      this.rackConfigsOriginal = deepCopy(this.rackConfigs);
    },
    async save(config) {
      this.parseChange(config); // in case it isn't validated
      await api.post('v2/order/rack-configs', config);
      await this.loadConfigs();
    },
    parseChange(config) {
      config.maxRacks = Number(config.maxRacks);
      config.mealsPerBinMinimum = Number(config.mealsPerBinMinimum);
      config.mealsPerBin = Number(config.mealsPerBin);
      config.rowsOfBins = Number(config.rowsOfBins);
      config.binsPerRow = Number(config.binsPerRow);
      if (config.locations && config.locations.split) {
        config.locations = config.locations.split(',').map(s => s.trim()).filter(s => !!s);
      }
    }
  }
}
</script>
