<template>
  <v-container fluid class=" pa-0">
    <v-progress-linear v-if="loading" :indeterminate="true"/>
    <v-dialog v-model="showExportDialog" persistent>
      <v-card>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined :loading="exporting" @click="showExportDialog=false">Close</v-btn>
        </v-card-actions>
        <v-card-title v-if="exporting">Exporting</v-card-title>
        <v-card-title v-if="!exporting">Exporting Completed</v-card-title>
        <v-card-text>
          <span v-if="exporting"> {{ exportStatus.tasksCompleted }} of {{ exportStatus.tasksToComplete }}</span>
          <span v-if="!exporting">{{ exportStatus.tasksToComplete }} uids exported</span>
          <v-list dense>
            <v-list-item v-for="(e,index) of (exportStatus.errors||[])" v-bind:key="index">
              <v-list-item-content>
                <v-list-item-title>
                  {{ e.errorMessage }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ formatOnfleetPayload(e.onfleetPayload) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined :loading="exporting" @click="showExportDialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAssignInProgress" max-width="400" persistent>
      <v-card>
        <v-card-title>Working...</v-card-title>
        <v-card-text>
          Assigning bins, please be patient, this will take a few moments
        </v-card-text>
        <v-card-text class="text-center">
          <v-progress-circular indeterminate/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showClearInProgress" max-width="400" persistent>
      <v-card>
        <v-card-title>Working...</v-card-title>
        <v-card-text>
          Clearing bin assignments, please be patient, this will take a few moments
        </v-card-text>
        <v-card-text class="text-center">
          <v-progress-circular indeterminate/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div class="orders" v-if="!loading">

      <v-dialog
        v-model="showMissing"
        ref="dialogMissing"
        width="500px"
      >
        <v-card>
          <v-card-title>Warning</v-card-title>
          <v-card-text>
            The following meal ids were not found in the database. Any ingredients required for these meals is not show.
            <v-list>
              <v-list-item v-for="mealId of missing_meals" v-bind:key="mealId">
                {{ missing_count[mealId] }} orders for {{ mealId }}
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-footer fixed class="d-print-none pl-10 pr-8" v-if="datesFormatted">
        {{ datesFormatted }}
        <v-spacer/>
        {{ totalCount }} meals
        {{ orders.length }} orders
        {{ selectedUids.length }} deliveries
        <v-spacer/>
        <v-chip
          v-if="missing_meals.size"
          color="warning" class="mr-1"
          @click="showMissing=true"
        >{{ missing_meals.size }} meals not found
        </v-chip>
        <!--      <v-chip color="secondary"-->
        <!--              close-->
        <!--              @click:close="selected=[]"-->
        <!--              v-if="totalRawSelected">Raw Selected {{ totalRawSelected }}-->
        <!--      </v-chip>-->
        <!--      <v-chip color="primary" v-if="!totalRawSelected">Raw Total {{ totalRawAll }}</v-chip>-->
      </v-footer>
      <v-alert class="d-print-none" color="red" v-if="isAdmin">Danger Danger! Admin mode</v-alert>
      <v-toolbar flat class="d-print-none">
        <v-spacer/>
        <v-btn-toggle
          v-model="groupBy"
          tile
          group
          prefix="Group By"
        >
          <v-btn value="customer">By Customer</v-btn>
          <v-btn value="meal">By Meal</v-btn>
          <v-btn value="rack">By Rack</v-btn>
          <v-btn value="none">No grouping</v-btn>
          <v-btn value="bag">Bag Labels</v-btn>
          <v-btn value="shippingLabel">Shipping Labels</v-btn>
          <v-btn value="deliveryList">Delivery List</v-btn>
          <v-btn value="raw">Data Export</v-btn>
          <v-btn value="bins">Bin Labels</v-btn>
        </v-btn-toggle>
        <v-spacer/>
      </v-toolbar>
      <v-toolbar flat class="d-print-none pb-10">
        <ProductionSelector
          :dates="dates" :production="production"
          v-on:update:dates="dates=$event"
          v-on:update:production="production=$event"
        />
        <!--                <v-spacer/>-->
        <!--                <v-text-field-->
        <!--                    v-model="search"-->
        <!--                    append-icon="mdi-magnify"-->
        <!--                    label="Search..."-->
        <!--                    single-line-->
        <!--                    hide-details-->
        <!--                />-->
        <v-spacer/>
        <v-select
          label="Filter by driver"
          v-model="driverSelected"
          :items="drivers"
          single-line
          hide-details
          clearable
          style="width: 20px"
        />
        <v-select
          class="pl-2"
          label="Location"
          flat
          hide-details
          v-model="filterLocation"
          :items="['','home','office']"
          style="width: 20px"
        />
        <v-checkbox
          class="pl-2 pt-2"
          label="UID"
          v-model="showUid"
          hide-details
        />
        <v-checkbox
          class="pl-2 pt-2"
          label="QR"
          v-model="showQRCodes"
          hide-details
        />
        <v-dialog
          v-model="showDatePicker"
          ref="dialog"
          width="290px"
          persistent
        >
          <v-date-picker
            v-model="datePickerDate"
            :range="isAdmin"
            no-title
            @close="!isAdmin && closeDatePicker()"
            @change="closeDatePicker"
          ></v-date-picker>
          <v-btn v-if="isAdmin" @click="closeDatePicker">Close</v-btn>
        </v-dialog>
      </v-toolbar>
      <div class="d-print-none my-5">
        <div class="pl-5">Filters</div>
        <v-toolbar class="d-print-none pl-0 justify-space-between" flat>
          <v-checkbox
            label="Last Bin Check"
            v-model="lastBinCheck"
            class="mr-3"
          />
          <v-checkbox
            label="With Notes"
            v-model="showNotes"
            class="mr-3"
          />
          <v-checkbox
            label="Box Racks Only"
            v-model="boxRackOnly"
            class="mr-3"
          />
          <v-spacer/>
          <v-select
            style="width: 100px"
            label="Exclude Tags"
            v-model="excludeTags"
            multiple
            :items="customerTags"
            clearable
            hint="Customers with these tags will be hidden below (eg, SPUD & Extras)"
            persistent-hint
          />
        </v-toolbar>
      </div>
      <template v-if="!readOnly">
        <v-toolbar flat outlined class="d-print-none" v-if="!isDelivery">
          <label>Assign orders to rack bins</label>

          <v-btn class="ml-2" @click="assignBins">Assign</v-btn>
          <v-btn class="ml-2" :to="{name: 'RackAssignment'}">Rack Configuration</v-btn>
          <template v-if="isAdmin">
            <v-btn outlined @click="clearBins" color="red" class="ml-2">
              <v-icon>mdi-warning</v-icon>
              Clear
            </v-btn>
          </template>
          <v-chip class="ml-2">{{ assignedOrders.length }} of {{ orders.length }} are assigned</v-chip>
        </v-toolbar>
        <v-dialog v-model="showLinkDialog" max-width="600px">
          <v-card>
            <v-card-title>Link Task</v-card-title>
            <v-card-text>
              This will create a link between an onfleet task and a FED order, so that ETA, driver assignment, etc, are
              shown below.
              You can use this to link manually created tasks in onfleet.
              <br/>
              {{ onfleetUid }} {{ onfleetShortId }}
              <v-text-field
                label="Onfleet ShortId"
                v-model="onfleetShortId"
                clearable
                class="mr-2"
                hint="Something like this: d588f4d4"
              />
              <v-autocomplete
                label="Link Delivery"
                v-model="onfleetUid"
                class="mr-2"
                :items="ordersByAddress"
                item-value="key"
                item-text="value"
                clearable
              />
            </v-card-text>
            <v-card-actions>
              <v-btn @click="showLinkDialog=false">Cancel</v-btn>
              <v-spacer/>
              <v-btn @click="linkTask(onfleetUid,onfleetShortId)">Link</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-toolbar flat class="d-print-none" v-if="isDelivery">
          <v-btn :disabled="!isAdmin" outlined @click="showLinkDialog=true">Link Onfleet Task</v-btn>
          <v-spacer/>
          <v-text-field
            v-if="isAdmin"
            class="ml-2"
            flat
            hide-details
            label="Override Ship Date"
            v-model="overrideShipDate"
            placeholder="use YYYY-MM-DD date format, eg 2022-12-26"
          />
          <v-btn :disabled="!isAdmin" outlined class="ml-2" :loading="exporting" @click="exportToOnfleet">Export to
            Onfleet
          </v-btn>
          <v-checkbox label="Show Errors" class="ml-2" v-model="onlyErrors" hide-details/>
        </v-toolbar>
      </template>
      <template v-if="groupBy==='bins'">
        <BinLabel
          v-for="(label,index) of binLabels" v-bind:key="index"
          :label="label"
          :qr-code-value="`target-bin=${label}`"
        />
      </template>
      <template v-if="groupBy==='raw'">
        <v-btn outlined @click="csvExport(rawItemsAsTable,exportFilename)">Download CSV</v-btn>
        <v-data-table
          :items="rawItems"
          :headers="rawHeaders"
        />
      </template>
      <template v-if="groupBy==='rack'">
        <v-card
          style="page-break-after: always;"
          flat
          v-for="(rack,index) of racks"
          v-bind:key="rack"
        >
          <div v-if="index>0" style="page-break-before: always">
            &nbsp;
          </div>
          <v-card-title>
            <v-spacer/>
            {{ rack }}
            <v-spacer/>
          </v-card-title>
          <v-row
            style="page-break-inside: avoid;"
            v-for="(customerOrders,customerName) of sortByBin(groupByCustomer(filterByRack(orders,rack)))"
            v-bind:key="customerName"
            :style="hasComment(customerOrders[0])?'border: 2px solid red':''"
          >

            <v-col cols="1" class="text-center" align-self="center" style="font-size: 40px">
              <input type="checkbox" style="width: 30px; height: 30px;">
              {{ getCount(customerOrders) }}
            </v-col>
            <v-col>
              <h4>
                <v-btn outlined x-small class="d-print-none" v-if="customerOrders[0].customer.uid"
                       :to="{name:'Customer',params:{id:customerOrders[0].customer.uid}}" target="_blank">
                  Edit Customer
                </v-btn>
                {{ customerName }}
                <template v-if="customerOrders[0].customer.last_bin_check">
                  <span class="d-print-none"> (last bin check) </span>
                </template>
                <template v-if="getCount(customerOrders)>0">
                  - {{ getCount(customerOrders) }} items
                  -
                  {{ getCustomerLocations(customerOrders).join(',') }}
                  delivery on
                  <span
                    v-for="(date,index) of getCustomerDeliveryDates(customerOrders)"
                    v-bind:key="date"
                  ><template v-if="index!==0">,</template> {{ dateFormatted(date) }}</span>

                  <span v-if="customerOrders[0].eta">
                    @ {{ dateFormatted(customerOrders[0].eta, {formatString: 'h:mma'}) }}
                  </span>

                  <span v-if="isFirstOrder(customerOrders[0])">
                    😃
                    <input type="checkbox" style="width: 20px; height: 20px;"/>
                  </span>
                  <span class="pl-4 red--text" v-if="hasComment(customerOrders[0])">
                    <div v-for="(comment,i) of hasComment(customerOrders[0])" v-bind:key="i">
                      <input type="checkbox" style="width: 20px; height: 20px;"/>
                      {{ comment }}
                    </div>
                  </span>
                </template>

              </h4>
              <ul v-if="isDelivery">
                <li v-for="(s,index) of getDeliveryStatus(customerOrders)" v-bind:key="index">
                  {{ s.day }} : {{ s.status }}
                  <v-alert type="error" v-if="s.error">{{ s.error }}</v-alert>
                  <!--                    <br/>-->
                  <!--                    {{ s }}-->
                </li>
              </ul>

              <template v-if="getCount(customerOrders)>0">
                <div v-for="(order,index) of customerOrders" v-bind:key="index">
                  <v-chip
                    x-small outlined
                    v-for="bin of (order.bin || '?').split(',')"
                    v-bind:key="bin"
                  >
                    {{ bin }}
                  </v-chip>
                  <span :class="order.tod==='extras'?'font-weight-bold':''">
                      {{ order.quantity }}
                      - <span style="display: inline-block; min-width: 65px">{{ order.tod }}</span>
                      - ({{ order.stream }}, {{ order.meal_size }})
                      - <span style="display: inline-block; min-width: 35px">{{
                      dateFormatted(order.date, {formatString: 'ddd'})
                    }}</span>
                      - {{ order.mealName }}
                      </span>
                  <v-chip v-for="a of order.displayAllergies" v-bind:key="a" x-small outlined class="pa-2 ml-1">{{
                      a
                    }}
                  </v-chip>
                </div>
              </template>

            </v-col>
            <v-col cols="2" class="text-center" align-self="center">
              <!--                <span v-if="isFirstOrder(customerOrders[0])">-->
              <!--                    😃-->
              <!--                    <input type="checkbox" style="width: 20px; height: 20px;"/>-->
              <!--                  </span>-->
              <!--                <span class="pl-4 red&#45;&#45;text" v-if="hasComment(customerOrders[0])">-->
              <!--                    <div v-for="(comment,i) of hasComment(customerOrders[0])" v-bind:key="i">-->
              <!--                      <input type="checkbox" style="width: 20px; height: 20px;"/>-->
              <!--                      {{ comment }}-->
              <!--                    </div>-->
              <!--                  </span>-->

              <template v-if="getCount(filterByTod(customerOrders, 'extras', true))>0">
                <div style="font-size: 40px">
                  <input type="checkbox" style="width: 30px; height: 30px;"/>
                  {{ getCount(filterByTod(customerOrders, 'extras', true)) }}
                </div>
                <br/>
                extras
              </template>
              <p style="color: red; font-weight: bolder" v-if="isFirstOrder(customerOrders[0])">
                  <span style="font-size: 35px">
                    <input type="checkbox" style="width: 30px; height: 30px;"/>😃
                  </span>
                <br/>new cust. pack
              </p>
            </v-col>
          </v-row>
        </v-card>
      </template>
      <template v-if="groupBy==='customer' || groupBy==='meal'">
        <v-card
          style="page-break-after: always;"
          flat
          v-for="(driver,index) of driversFiltered"
          v-bind:key="driver"
        >
          <div v-if="index>0" style="page-break-before: always">
            &nbsp;
          </div>
          <v-card-title>
            Driver: {{ driver }}
          </v-card-title>
          <template v-if="groupBy==='customer'">
            <v-row
              style="page-break-inside: avoid;"
              v-for="(customerOrders,customerName) of groupByCustomer(orders.filter(o => o.driver === driver))"
              v-bind:key="customerName"
              :style="hasComment(customerOrders[0])?'border: 2px solid red':''"
            >
              <v-col cols="1" class="text-center" align-self="center" style="font-size: 40px">
                <input type="checkbox" style="width: 30px; height: 30px;">
                {{ getCount(customerOrders) }}
              </v-col>
              <v-col>
                <h4>
                  <v-btn outlined x-small class="d-print-none" v-if="customerOrders[0].customer.uid"
                         :to="{name:'Customer',params:{id:customerOrders[0].customer.uid}}" target="_blank">
                    Edit Customer
                  </v-btn>
                  {{ customerName }}
                  <template v-if="customerOrders[0].customer.last_bin_check">
                    <span class="d-print-none"> (last bin check) </span>
                  </template>
                  <template v-if="getCount(customerOrders)>0">
                    - {{ getCount(customerOrders) }} items
                    -
                    {{ getCustomerLocations(customerOrders).join(',') }}
                    delivery on
                    <span
                      v-for="(date,index) of getCustomerDeliveryDates(customerOrders)"
                      v-bind:key="date"
                    ><template v-if="index!==0">,</template> {{ dateFormatted(date) }}</span>

                    <span v-if="customerOrders[0].eta">
                    @ {{ dateFormatted(customerOrders[0].eta, {formatString: 'h:mma'}) }}
                  </span>

                    <span v-if="isFirstOrder(customerOrders[0])">
                    😃
                    <input type="checkbox" style="width: 20px; height: 20px;"/>
                  </span>
                    <span class="pl-4 red--text" v-if="hasComment(customerOrders[0])">
                    <div v-for="(comment,i) of hasComment(customerOrders[0])" v-bind:key="i">
                      <input type="checkbox" style="width: 20px; height: 20px;"/>
                      {{ comment }}
                    </div>
                  </span>
                  </template>

                </h4>
                <ul v-if="isDelivery">
                  <li v-for="(s,index) of getDeliveryStatus(customerOrders)" v-bind:key="index">
                    {{ s.day }} : {{ s.status }}
                    <v-alert type="error" v-if="s.error">{{ s.error }}</v-alert>
                    <!--                    <br/>-->
                    <!--                    {{ s }}-->
                  </li>
                </ul>

                <template v-if="getCount(customerOrders)>0">
                  <div v-for="(order,index) of customerOrders" v-bind:key="index">
                    <v-chip
                      x-small outlined
                      v-for="bin of (order.bin || '?').split(',')"
                      v-bind:key="bin"
                    >
                      {{ bin }}
                    </v-chip>
                    <span :class="order.tod==='extras'?'font-weight-bold':''">
                      {{ order.quantity }}
                      - <span style="display: inline-block; min-width: 65px">{{ order.tod }}</span>
                      - ({{ order.stream }}, {{ order.meal_size }})
                      - <span style="display: inline-block; min-width: 35px">{{
                        dateFormatted(order.date, {formatString: 'ddd'})
                      }}</span>
                      - {{ order.mealName }}
                      </span>
                    <v-chip v-for="a of order.displayAllergies" v-bind:key="a" x-small outlined class="pa-2 ml-1">{{
                        a
                      }}
                    </v-chip>
                  </div>
                </template>

              </v-col>
              <v-col cols="2" class="text-center" align-self="center">
                <!--                <span v-if="isFirstOrder(customerOrders[0])">-->
                <!--                    😃-->
                <!--                    <input type="checkbox" style="width: 20px; height: 20px;"/>-->
                <!--                  </span>-->
                <!--                <span class="pl-4 red&#45;&#45;text" v-if="hasComment(customerOrders[0])">-->
                <!--                    <div v-for="(comment,i) of hasComment(customerOrders[0])" v-bind:key="i">-->
                <!--                      <input type="checkbox" style="width: 20px; height: 20px;"/>-->
                <!--                      {{ comment }}-->
                <!--                    </div>-->
                <!--                  </span>-->

                <template v-if="getCount(filterByTod(customerOrders, 'extras', true))>0">
                  <div style="font-size: 40px">
                    <input type="checkbox" style="width: 30px; height: 30px;"/>
                    {{ getCount(filterByTod(customerOrders, 'extras', true)) }}
                  </div>
                  <br/>
                  extras
                </template>
                <p style="color: red; font-weight: bolder" v-if="isFirstOrder(customerOrders[0])">
                  <span style="font-size: 35px">
                    <input type="checkbox" style="width: 30px; height: 30px;"/>😃
                  </span>
                  <br/>new cust. pack
                </p>
              </v-col>
            </v-row>
          </template>
          <template v-if="groupBy==='meal'">
            <v-card-text
              v-for="(mealOrders,mealName) of groupByMeal(orders.filter(o => o.driver === driver))"
              v-bind:key="mealName">
              <h4>{{ mealName }} - Meal Count {{ getCount(mealOrders) }}</h4>
              <ul>
                <li v-for="(order,index) of sortBySize(mealOrders)" v-bind:key="index">
                  <v-chip
                    x-small outlined
                    v-for="bin of (order.bin || '?').split(',')"
                    v-bind:key="bin"
                  >
                    {{ bin }}
                  </v-chip>
                  {{ order.quantity }} - {{ order.tod }} - {{ order.name }}
                  ({{ order.meal_size }}) - {{ dateFormatted(order.date) }}
                  <span v-for="a of order.displayAllergies" v-bind:key="a">
                    <v-chip x-small outlined class="pa-2">{{ a }}</v-chip>
                  </span>
                </li>
              </ul>
            </v-card-text>
          </template>
        </v-card>
      </template>
      <template v-if="groupBy==='none'">
        <v-card-text
          v-for="(mealOrders,mealName) of groupByMeal(orders)"
          v-bind:key="mealName">
          <h4>{{ mealName }} - Meal Count {{ getCount(mealOrders) }}</h4>
          <ul>
            <li v-for="(order,index) of sortBySize(mealOrders)" v-bind:key="index">
              <v-chip
                x-small outlined
                v-for="bin of (order.bin || '?').split(',')"
                v-bind:key="bin"
              >
                {{ bin }}
              </v-chip>
              {{ order.quantity }} - {{ order.tod }} - {{ order.name }}
              ({{ order.meal_size }})
              - {{ dateFormatted(order.date) }}
              <span
                v-for="a of order.displayAllergies" v-bind:key="a">
                  <v-chip x-small outlined class="pa-2">{{ a }}</v-chip>
                </span>
              - Driver: {{ order.driver }}
            </li>
          </ul>
        </v-card-text>
      </template>
      <template v-if="groupBy==='bag'">
        <v-container fluid class="pa-0">
          <v-toolbar class="d-print-none">
            <v-spacer/>
            <v-btn-toggle v-model="bagStickerSelector">
              <v-btn value="box">Box Rack</v-btn>
              <v-btn value="regular">Regular Rack</v-btn>
            </v-btn-toggle>
            <v-spacer/>
          </v-toolbar>
          <div v-for="(bag,index) of bags" v-bind:key="`driver-${index}-${bag.driver}`"
               style="border: dot-dot-dash black 1px">
            <BagDriverLabel
              v-if="bag.isDriverLabel"
              :driver="bag.driver"
              :bag-count="bag.count"
            />
            <template v-if="!bag.isDriverLabel">
              <div
                v-for="i of range(1,bag.count+1)"
                v-bind:key="`${index}-${i}-${showQRCodes}-${bag.driver}`">
                <!--                <div class="d-print-none ma-4" v-if="bag.eta">-->
                <!--                  <hr/>-->
                <!--                  <h1>{{ dateFormatted(bag.eta, {formatString: 'h:mma'}) }}</h1>-->
                <!--                </div>-->
                <BagLabel
                  v-if="showQRCodes"
                  :driver="bag.driver"
                  :sequence="bag.sequence"
                  :customer-name="bag.customerName"
                  :bag="i"
                  :eta="bag.eta"
                  :bags="bag.count"
                  :bag-size="bag.bagSize"
                  :bins="bag.bins"
                  :qr-code-value="bag.qrCodeValues[i-1]"
                  :address_line_1="bag.address_line_1"
                  :address_line_2="bag.address_line_2"
                />
                <BagLabel
                  v-if="!showQRCodes"
                  :driver="bag.driver"
                  :sequence="bag.sequence"
                  :customer-name="bag.customerName"
                  :bag="i"
                  :eta="bag.eta"
                  :bags="bag.count"
                  :bag-size="bag.bagSize"
                  :bins="bag.bins"
                  :address_line_1="bag.address_line_1"
                  :address_line_2="bag.address_line_2"
                />
              </div>
            </template>
          </div>
        </v-container>
      </template>
      <template v-if="groupBy==='shippingLabel'">
        <v-card v-for="(orders,uid) of  shipments" v-bind:key="uid">
          <v-card-title>
            <router-link :to="{name:'Customer',params:{id:uid}}">{{ getCustomerName(uid) }}</router-link>
            ({{ getCustomer(uid).plan }}) :: {{ orders.reduce((sum, o) => o.quantity + sum, 0) }} meals
          </v-card-title>
          <v-card-text>
            <v-chip outlined>{{ getCustomer(uid).address.location }}</v-chip>
            {{ getCustomerName(uid) }}, {{ formatAddress(getCustomer(uid).address) }}
            <v-btn icon class="d-print-none"
                   @click="copyToClipboard(`${ getCustomerName(uid) }, ${ formatAddress(getCustomer(uid).address) }`)">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </v-card-text>
          <v-card-text>
            <div v-for="(order,index) of orders" v-bind:key="index">
              <v-chip
                x-small outlined
                v-for="bin of (order.bin || '?').split(',')"
                v-bind:key="bin"
              >
                {{ bin }}
              </v-chip>
              <span :class="order.tod==='extras'?'font-weight-bold':''">
                      {{ order.quantity }}
                      - <span style="display: inline-block; min-width: 65px">{{ order.tod }}</span>
                      - ({{ order.stream }}, {{ order.meal_size }})
                      - <span style="display: inline-block; min-width: 35px">{{
                  dateFormatted(order.date, {formatString: 'ddd'})
                }}</span>
                      - {{ order.mealName }}
                      </span>
              <v-chip v-for="a of order.displayAllergies" v-bind:key="a" x-small outlined class="pa-2 ml-1">{{
                  a
                }}
              </v-chip>
            </div>
          </v-card-text>
        </v-card>
      </template>
      <template v-if="groupBy==='deliveryList'">
        <v-card
          style="page-break-after: always;"
          flat
          v-for="driver of driversFiltered"
          v-bind:key="driver"
        >
          <v-card-title>
            Driver: {{ driver }}
          </v-card-title>
          <template>
            <v-row
              style="page-break-inside: avoid;"
              v-for="(customerOrders,customerName) of groupByCustomer(orders.filter(o => o.driver === driver))"
              v-bind:key="customerName"
            >
              <v-col cols="1" class="text-center" align-self="center" style="font-size: 40px">
                <input type="checkbox" style="width: 30px; height: 30px;">
                {{ getBagCount(customerOrders) }}
              </v-col>
              <v-col cols="3">
                <div class="font-weight-bold">{{ formatAddress(customerOrders[0].customer.address) }}</div>
                <div>
                  {{ customerName }} - {{ getCount(customerOrders) }} meals
                  -
                  <span
                    v-for="(date,index) of getCustomerDeliveryDates(customerOrders)"
                    v-bind:key="date"
                  ><template v-if="index!==0">,</template> {{
                      dateFormatted(date, {formatString: 'ddd MMM DD'})
                    }}</span>

                  <span v-if="customerOrders[0].eta">
                    @ {{ dateFormatted(customerOrders[0].eta, {formatString: 'h:mma'}) }}
                  </span>
                  <!--                  {{ customerOrders[0].uid }}-->
                  <span v-if="isFirstOrder(customerOrders[0])">
                    😃
                    <input type="checkbox" style="width: 20px; height: 20px;"/>
                  </span>
                </div>
                <div style="font-size: small" v-if="customerOrders[0].customer.delivery_instruction">
                  <b>Note:</b> {{ customerOrders[0].customer.delivery_instruction }}
                </div>
              </v-col>
            </v-row>
          </template>
        </v-card>
      </template>
      <!-- blank gap at the bottom to avoid fixed footer hiding data-table -->
      <div class="ma-8"></div>
    </div>
  </v-container>
</template>


<script>
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment';
import {csvExport, dateFormatted, getDateString, getProductionDays, promisePause} from '@/store/utils'
import api from '@/api';
import BagLabel from "@/components/BagLabel";
import BagCalc from "@/store/bag-calc";
import {range} from "lodash";
import BagDriverLabel from "@/components/BagDriverLabel";
import urlState from "@/router/urlState";
import BinLabel from "@/components/BinLabel.vue";
import ProductionSelector from "@/components/ProductionSelector.vue";
import diff from "microdiff";


const binRegEx = new RegExp(/^(Green|Blue|Grey|Yellow|Ash|Charcoal|Purple|Red)/)

function isBoxBin(bin) {
  return bin && binRegEx.test(bin);
}

export default {
  components: {ProductionSelector, BinLabel, BagDriverLabel, BagLabel},
  mixins: [urlState],
  data() {
    return {
      units: ['g', 'kg'],
      decimalPlaces: [1, 2],
      streamsSelected: [],
      driverSelected: null,
      selected: [],
      missing_meals: new Set(),
      missing_count: {},
      showDatePicker: false,
      dates: [],
      search: '',
      loading: true,
      post: null,
      error: null,
      multiview: false,
      multiviewMealSelected: {},
      headers: [
        // {
        //   text: 'Email',
        //   align: 'start',
        //   value: 'email',
        //   width: '*'
        // },
        {text: 'Driver', value: 'driver'},
        {text: 'Customer Name', value: 'name',},
        {text: 'Quantity', value: 'quantity'},
        // {text: 'Meal ID', value: 'meal_id'},
        {text: 'Meal', value: 'mealName'},
        {text: 'Stream', value: 'stream'},
        {text: 'Allergy/Restrictions', value: 'displayAllergies',},

        {text: 'Time of day', value: 'tod'},
        {text: 'Date', value: 'date'}

      ],
      showMissing: false,
      filteredIngredients: [],
      maxEmailPerLink: 450,
      groupBy: 'customer',
      showAssignInProgress: null,
      showClearInProgress: null,
      datePickerDate: null,
      filterLocation: null,
      exporting: null,
      overrideShipDate: '',
      onlyErrors: false,
      showUid: false,
      showExportDialog: null,
      exportStatus: {},
      onfleetShortId: '',
      showLinkDialog: false,
      onfleetUid: '',
      showQRCodes: true,
      excludeTags: [],
      binLabels: [],
      lastBinCheck: false,
      showNotes: false,
      driverTasks: [],
      bagStickerSelector: 'regular',
      boxRackOnly: null,
      production: null,
      ordersFetched: []
    }
  },
  async mounted() {
    this.syncToUrl({
      param: 'filterLocation', urlParam: 'location', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'search', urlParam: 'search', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'production', urlParam: 'production', initFromRoute: true,
    });
    this.syncToUrl({
      param: 'driverSelected', urlParam: 'driver', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'groupBy', urlParam: 'group', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'bagStickerSelector', urlParam: 'rack', initFromRoute: true,
      // parseCallback: (v) => v === 'true'
    });
    this.syncToUrl({
      param: 'onlyErrors', urlParam: 'errors', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    // this.syncToUrl({
    //   param: 'lastBinCheck', urlParam: 'last_bin_check', initFromRoute: true,
    //   parseCallback: (v) => v === 'true'
    // });
    this.syncToUrl({
      param: 'showQRCodes', urlParam: 'qr', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });

    const params = ['lastBinCheck', 'showNotes', 'boxRackOnly'];
    params.forEach(param => {
      this.syncToUrl({
        param, urlParam: param, initFromRoute: true,
        parseCallback: (v) => v === 'true'
      })
    })

    this.excludeTags = ['DoNotPrint'];
    this.setDates();
    await this.fetchData();
    api.get('v2/order/bins').then(r => {
      // console.log('bin labels',r.data);
      this.binLabels = r.data;
    })
  },
  watch: {
    dates: 'fetchData',
    production: 'fetchData',
    showDatePicker(v) {
      if (v) {
        if (this.isAdmin) {
          this.datePickerDate = this.dates;
        } else {
          this.datePickerDate = this.dates[0];
        }
      } else {
        if (this.isAdmin) {
          this.dates = this.datePickerDate;
        } else {
          this.dates = getProductionDays(this.datePickerDate);
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      'meals',
      'diets',
      'getMeal',
      'getMealIngredients',
      'getDietName',
      'getDietStream',
      'getTimeOfDay',
      'getSummaryForDiet',
      'getCustomer'
    ]),
    readOnly() {
      return !!this.$route.meta.readOnly;
    },
    isFiltered() {
      const allOrders = this.ordersUnfiltered.length;
      const displayedOrders = this.ordersFiltered.length;
      // console.log('is fitlered', {allOrders, displayedOrders});
      return allOrders !== displayedOrders;
    },
    ordersByAddress() {
      // console.log('orders', this.orders);
      const result = {};
      this.orders.forEach(o => {
        const {customer} = o;
        const {address} = customer;
        const display = [customer.first_name, customer.last_name, address.unit || '', address.line1, address.postal_code].join(' ');
        result[o.uid] = display
      })
      return Object.entries(result)
        .map(([key, value]) => ({key, value}));
    },

    ordersUnfiltered() {
      const orders = this.ordersFetched;
      // console.log('concat orders from dates', {dates: this.dates});
      // orders.forEach(o => o.name = `${o.last_name}, ${o.first_name}`);
      orders.forEach(o => o.name = `${o.first_name} ${o.last_name}`);
      orders.forEach(o => o.mealName = this.getMeal(o.meal_id) && this.getMeal(o.meal_id).name);
      orders.forEach(o => o.customer = this.getCustomer(o.uid));
      orders.forEach(o => o.email = o.customer ? o.customer.email : '');
      orders.forEach(o => o.driver = o.driver || 'Unassigned');
//      console.log('orders', orders);
      const ship_dates = [...new Set(orders.map(o => o.ship_date))];
      this.driverTasks
        .filter(dt => dt.task.name === 'fed')
        .forEach(dt => {
          const task = dt.task;
          // const worker = dt.worker;
          const ship_date = moment(task.eta).format(moment.HTML5_FMT.DATE);
          // console.log('checking task ship date',ship_date,dt)
          if (!ship_dates.includes(ship_date)) {
            // console.log('ship date excluded',ship_date, task.eta, worker.name)
          } else {
            const taskAddress = task.address;
            const address = {
              unit: taskAddress.apartment || '',
              state: 'British Columbia',
              postal_code: taskAddress.postalCode || '', //: 'V6A 2M9',
              line1: `${taskAddress.number} ${taskAddress.street}`,
              city: taskAddress.city || '', // : 'Vancouver',
              country: taskAddress.country || '', //: 'Canada'
              delivery_instruction: task.notes
            }
            const name = (dt.task.name === 'fed')
              ? 'RETURN TO FED FOR NEXT RUN'
              : dt.task.name + ' (onfleet)';
            const fauxOrder = {
              isOnfleetTask: true,
              shortId: task.shortId,
              eta: task.eta,
              name,
              driver: dt.worker.name,
              customer: {
                address,
              },
              address,
              quantity: 0
            };
            console.log('adding faux order', fauxOrder);
            orders.push(fauxOrder);
          }
        });
      return orders;
    },
    ordersFiltered() {
      let orders = this.ordersUnfiltered;
      // console.log('orders', orders);
      // const streamsSelected = this.streamsSelected;
      if (this.driverSelected) {
        orders = orders.filter(o => o.driver === this.driverSelected);
      }

      if (this.onlyErrors) {
        orders = orders.filter(o => {
          const [deliveryStatus = {}] = this.getDeliveryStatus([o]);
          return !!deliveryStatus.error;
        });
      }

      if (this.filterLocation) {
        orders = orders.filter(o => o.customer.address.location === this.filterLocation);
      }

      if (this.excludeTags && this.excludeTags.length > 0) {
        orders = orders.filter(o => !o.customer || !(o.customer.tags && o.customer.tags.some(tag => this.excludeTags.includes(tag))));
      }

      if (this.lastBinCheck) {
        orders = orders.filter(o => o.customer.last_bin_check);
      }
      if (this.showNotes) {
        orders = orders.filter(this.hasComment);
      }
      if (this.boxRackOnly) {
        orders = orders.filter(o => isBoxBin(o.bin));
      }
      return orders;
    },
    orders() {
      // used to do fruit cup stuff here
      return this.ordersFiltered;
    },
    totalCount() {
      let count = 0;
      this.orders.forEach(o => count += o.quantity);
      return count;
    },
    mealsByDiet() {
      console.log('this.getMealsByDiet(this.date)', this.getMealsByDiet(this.date));
      return this.getMealsByDiet(this.date);
    },
    summary() {
      return this.$store.getters.summary(this.date);
    },
    drivers() {
      const drivers = new Set();
      this.ordersUnfiltered.forEach(o => drivers.add(o.driver));
      const sortedDrivers = [...drivers.values()].filter(d => !!d).sort();
      console.log('drivers', sortedDrivers);
      return sortedDrivers;
    },
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
    shipments() {
      let {orders} = this;
      orders = orders.filter(o => o.customer.address.use_shipping);
      return this.groupByCustomer(orders, 'uid');
    },
    bags() {
      let {orders, driverSelected, driversFiltered} = this;
      orders = orders.filter(o => !o.isOnfleetTask);
      const result = [];


      function binSort({bins: binA}, {bins: binB}) {
        return binA.join(',').localeCompare(binB.join(','));
      }

      if (this.bagStickerSelector === 'box') {
        const {driverCounts, customerLabels} = BagCalc.computeBags(
          this.groupByCustomer(orders.filter(o => isBoxBin(o.bin)))
        )
        console.log('box rack selected', driverCounts, customerLabels);
        return customerLabels.sort(binSort);
      }

      if (this.bagStickerSelector === 'regular') {
        orders = orders.filter(o => !isBoxBin(o.bin));
      }


      let drivers;
      if (driverSelected) {
        orders = orders.filter(o => o.driver === driverSelected);
        drivers = [driverSelected];
      } else {
        drivers = ['Unassigned', ...driversFiltered.filter(d => d !== 'Unassigned')]
      }
      for (const driver of drivers) {
        const {driverCounts, customerLabels} = BagCalc.computeBags(
          this.groupByCustomer(orders.filter(o => o.driver === driver))
        );
        if (customerLabels.length > 0) {
          result.push({
            isDriverLabel: true,
            driver: driver,
            count: driverCounts[driver]
          })
          result.push(...customerLabels.filter(c => c.driver === driver));
        }
      }

      return result;
    },
    selectedUids() {
      return [...new Set(this.ordersFiltered.map(o => o.uid))];
    },
    rawHeaders() {
      const headers = [
        {
          value: 'bin',
          text: 'bin'
        },
        {
          "value": "name",
          "text": "name"
        },
        {
          "value": "stream",
          "text": "stream"
        },
        {
          "value": "meal_size",
          "text": "meal_size"
        },
        // {
        //   "value": "order_type",
        //   "text": "order_type"
        // },
        {
          "value": "tod",
          "text": "tod"
        },
        // {
        //   "value": "lineItemRef",
        //   "text": "lineItemRef"
        // },
        // {
        //   "value": "uid",
        //   "text": "uid"
        // },
        {
          "value": "displayAllergies",
          "text": "displayAllergies"
        },
        // {
        //   "value": "order_id",
        //   "text": "order_id"
        // },
        // {
        //   "value": "id",
        //   "text": "id"
        // },
        // {
        //   "value": "first_name",
        //   "text": "first_name"
        // },
        // {
        //   "value": "last_name",
        //   "text": "last_name"
        // },
        // {
        //   "value": "email",
        //   "text": "email"
        // },
        // {
        //   "value": "phone_number",
        //   "text": "phone_number"
        // },
        // {
        //   "value": "plan",
        //   "text": "plan"
        // },
        {
          "value": "meal_id",
          "text": "meal_id"
        },
        {
          "value": "mealName",
          "text": "mealName"
        },
        {
          "value": "quantity",
          "text": "quantity"
        },
        {
          "value": "date",
          "text": "date"
        },
        {
          "value": "ship_date",
          "text": "ship_date"
        },

        // {
        //   "value": "customer",
        //   "text": "customer"
        // },
        // {
        //   "value": "driver",
        //   "text": "driver"
        // }
      ];
      return headers;
    },
    rawItems() {
      const items = this.ordersFiltered;
      console.log('items', items);
      return items;
    },
    exportFilename() {
      return `packing-${this.dates.join('-')}-${this.production}`;
    },
    rawItemsAsTable() {
      const headers = this.rawHeaders;
      return this.rawItems.map(item => {
        const result = {};
        headers.forEach(h => result[h.value] = item[h.value]);
        return result;
      });
    },
    customerTags() {
      const tags = new Set();
      this.ordersUnfiltered
        .forEach(order => {
            if (order.tags) {
              order.tags.forEach(t => tags.add(t));
            }
          }
        );
      return [...tags];
    },
    driversFiltered() {
      const {drivers, driverSelected} = this;
      return drivers.filter(d => driverSelected ? d === driverSelected : true)
        .filter(d => this.orders.some(o => o.driver === d))
    },
    assignedOrders() {
      return this.orders.filter(o => !!o.bin);
    },
    racks() {
      return [...new Set(this.orders.map(o => (o.bin || '').split('-')[0]))].sort();
    }
  },
  methods: {
    ...mapActions([
      'fetchDiets',
      'fetchMealsById',
      'fetchMealIngredients',
      'fetchOrdersByDate',
      'fetchCustomers',
      'reload'
    ]),
    csvExport,
    range,
    getBagCount(orders) {
      return BagCalc.getNumberOfBags(orders);
    },
    setDates() {
      const dates = this.dates;
      console.log('set dates', dates.length, dates);
      if (this.isAdmin) {
        // this.dates = dates;
      } else {
        if (dates.length === 1) {
          this.dates = getProductionDays(dates[0]);
        }
      }
      console.log('set dates', this.dates);
    },
    getDatesInRange() {
      const dates = [];
      if (!this.dateFrom || !this.dateTo) {
        return dates;
      }
      const from = moment(this.dateFrom);
      const to = moment(this.dateTo);

      const diff = moment.duration(to.diff(from));
      if (diff.asDays() >= 0 && diff.asDays() <= 31) {
        // console.log('adding days ', {from, to})
        while (from.isSameOrBefore(to)) {
          const dateString = getDateString(from);
          // console.log('added date', dateString);
          dates.push(dateString);
          from.add(1, 'day');
        }
      } else {
        window.alert(diff.asDays() + ' days is above the limit of 7');
      }
      // console.log('getDatesInRange', dates);
      return dates;
    },
    async fetchData() {
      this.error = this.post = null

      const {dates, production} = this;
      if (!dates || dates.length === 0) {
        console.log('no dates, no fetch');
        this.loading = false;
        return;
      }
      if (!production) {
        console.log('no production, no fetch');
        this.loading = false;
        return;
      }

      if (this.currentData && diff(this.currentData, {dates, production}).length === 0) {
        console.log('already loading', this.currentData);
        return;
      } else {
        console.log('loading packing', {dates, production});
        this.currentData = {dates, production};
      }


      this.loading = true;
      const orders = await this.fetchOrders();

      const meals = {};
      // console.log('ordersByDate', ordersByDate);
      orders.forEach(order => {
        const mealCount = meals[order.meal_id] || {};
        mealCount[order.meal_size] = mealCount[order.meal_size] || 0;
        mealCount[order.meal_size]++;
        meals[order.meal_id] = mealCount;
      });
      // console.log('meals', meals);
      await Promise.all([
          this.fetchMealsById(Object.keys(meals))
            .then((meals)=>console.log('meals loaded',meals)),
          this.fetchDriverTasks(),
          this.fetchCustomers()
        ]
      ).catch((e) => {
        console.error('something went wrong', e);
      })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchDriverTasks() {
      return api.get('v2/onfleet/driver-tasks')
        .then(({data}) => {
          console.log('onfleet/driver-tasks', data);
          this.driverTasks = data;
        });
    },
    meal_sizes() {
      return 'small,medium,large'.split(',');
    },
    closeDatePicker() {
      this.showDatePicker = false;
      this.$nextTick(() => this.fetchData());
    },
    dateFormatted,
    getEmails(orders) {
      const emails = new Set()
      orders.forEach(o => o.email && emails.add(o.email));
      // orders.forEach(o => o.email && emails.add(`${o.first_name} ${o.last_name} <${o.email}>`));
      return [...emails];
    },
    getEmailChunks(orders, chunkSize) {
      const emails = this.getEmails(orders);
      const chunks = [];
      let i = 0;
      while (i < emails.length) {
        const upper = Math.min(chunkSize + i, emails.length);
        chunks.push({chunk: `${i + 1} to ${upper}`, emails: emails.slice(i, upper)});
        i += chunkSize;
      }
      return chunks;
    },
    sortByBin(groupedOrders) {
      const entries = Object.entries(groupedOrders);
      const sortedEntries = entries.sort((e1, e2) => (e1[1][0].bin || '').localeCompare(e2[1][0].bin || ''));
      return Object.fromEntries(
        sortedEntries
      );
    },
    groupByCustomer(orders, key = 'name') {
      const result = {};
      orders.forEach(o => {
        if (key === 'name') {
          const name = (this.showUid ? o.name + '-' + o.uid : o.name).trim();
          result[name] = result[name] || [];
          result[name].push(o);
        }
        if (key === 'uid') {
          const name = o.uid;
          result[name] = result[name] || [];
          result[name].push(o);
        }
      });
      const sortedKeys = Object.keys(result).sort((a, b) => {
        const [orderA] = result[a];
        const [orderB] = result[b];
        if (orderA && orderA.eta && orderB && orderB.eta) {
          return orderA.eta - orderB.eta
        } else {
          return a.localeCompare(b);
        }
      });
      const sortedResult = {};
      sortedKeys.forEach(c => sortedResult[c] = result[c]);
      return sortedResult;
    },
    groupByMeal(orders) {
      const result = {};
      orders.forEach(o => {
        const name = `${o.mealName} (${o.stream}-${o.meal_id})`;
        result[name] = result[name] || [];
        result[name].push(o);
      });
      const sortedKeys = Object.keys(result).sort();
      const sortedResult = {};
      sortedKeys.forEach(c => sortedResult[c] = result[c]);
      return sortedResult;
    },
    timeOfDay(a, b) {
      return ((a.tod || '').localeCompare((b.tod | '')));
    },
    getCount(mealOrders) {
      return mealOrders.map(m => m.quantity).reduce((a, s) => a + s, 0);
    },
    hasComment(customerOrder) {
      let date = customerOrder.ship_date;
      const shipDate = moment(customerOrder.ship_date)
      if (shipDate.format('ddd') === 'Mon') {
        date = shipDate.subtract(1, 'day').format(moment.HTML5_FMT.DATE);
      }

      function inRange({start, end}) {
        return (!start || start <= date) && (!end || end >= date)
      }

      const customer = customerOrder && customerOrder.customer;
      const comments = [];

      const comment = customer && customer.comment;
      if (comment) comments.push(comment);
      if (customer.customer_notes) {
        const activeNotes = customer.customer_notes.filter(inRange);
        activeNotes.forEach(note => comments.push(note.text))
      }
      return comments.length > 0 ? comments : false;
    },
    isFirstOrder(customerOrder) {
      return customerOrder && customerOrder.isFirstOrder;
    },
    assignBins() {
      const standardWarning = `
Are you sure you would like to assign bins?  This must not be performed after printing!

Any existing bin labels will *not* be changed.
`;
      const adminWarning = `You are using the ADMIN version.

Are you sure you would like to assign bins?  This must not be performed after printing!

Any existing bin labels will *not* be changed.

Note that ANY dates can be selected (eg, Mon/Tue, or Mon, Tue, Wed) for this to work.`
      if (confirm(this.isAdmin ? adminWarning : standardWarning)) {
        this.showAssignInProgress = true;
        const payload = {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          productions: [this.production]
        };
        if (this.isAdmin) {
          payload['isAdmin'] = true;
        }
        api.post('v2/order/assign-bins', payload)
          .then(() => console.log('post finished, pausing'))
          .then(() => promisePause(5000))
          .then(() => console.log('pause elapsed, fetching'))
          .then(() => this.fetchOrders())
          .finally(() => this.showAssignInProgress = false);
      }
    },
    clearBins() {
      const dateFrom = this.dateFrom;
      const dateTo = this.dateTo;
      if (confirm(`
Are you sure you would like to clear bin assignments for all orders between ${dateFrom} and ${dateTo}?

This should not be performed after printing meal labels.
`)) {
        this.showClearInProgress = true;
        const payload = {
          dateFrom: dateFrom,
          dateTo: dateTo,
          productions: [this.production]
        };
        if (this.isAdmin) {
          payload['isAdmin'] = true;
        }
        api.post('v2/order/clear-bins', payload)
          .then(() => console.log('post finished, pausing'))
          .then(() => promisePause(5000))
          .then(() => console.log('pause elapsed, fetching'))
          .then(() => this.fetchOrders())
          .finally(() => this.showClearInProgress = false);
      }
    },
    sortBySize(orders) {
      const sizeMap = {small: 0, medium: 1, large: 2};
      const size = s => sizeMap[s.meal_size];
      const sortFunction = (a, b) => size(a) - size(b) || a.name.localeCompare(b.name);
      return [...orders].sort(sortFunction);
    },
    filterByTod(customerOrders, tod, include) {
      return include
        ? customerOrders.filter(o => tod === o.tod)
        : customerOrders.filter(o => tod !== o.tod)
    },
    async exportToOnfleet() {
      this.exporting = true;
      const payload = {dateFrom: this.dateFrom, dateTo: this.dateTo};

      if (this.isFiltered) {
        const uids = this.selectedUids;
        console.log('filtered, so partial export', uids);
        payload.uids = uids;
      }
      if (this.overrideShipDate) {
        payload.forceShipDate = this.overrideShipDate;
      }

      this.showExportDialog = true;
      let exporting = true;

      const updateStatus = (r) => {
        console.log('response', r);
        this.exportStatus = r.data;
        exporting = this.exportStatus.exportInProcess;
      }

      await api.post('v2/onfleet/', payload)
        .then(r => updateStatus(r))
        .catch(e => console.error('fail', e))


      while (exporting) {
        await new Promise(resolve => setTimeout(() => resolve(), 1000));
        api.post('v2/onfleet/', payload, {params: {check: true}})
          .then(r => updateStatus(r))
          .catch(e => console.error('fail', e));
      }
      this.exporting = false;
    },
    getCustomerLocations(customerOrders) {
      return [...new Set(customerOrders.map(o => {
        const customer = o.customer || this.getCustomer(o.uid);
        return customer.address.location;
      }))];
    },
    getCustomerDeliveryDates(customerOrders) {
      return [...new Set(customerOrders.map(o => o.ship_date))];
    },
    getDeliveryStatus(customerOrders) {
      const logistics = customerOrders[0].logistics;
      if (!logistics) {
        return [];
      }
      /** FIXME: this isn't really great - shipments should not be saved inside each order record
       * we presume that the order line item record contains all the shipments of the week (not just
       * the day a particular line item is on */
      const ship_dates = [...new Set(customerOrders.map(o => o.ship_date))]
        .map(date => moment(date).format('ddd').toLowerCase());
      const filtered = logistics
        .filter(s => ship_dates.includes(s.day))
      if (filtered.length > 0) {
        return filtered;
      } else {
        return logistics;
      }
    },
    formatOnfleetPayload(payload) {
      return payload && payload.recipients
        ? payload.recipients.map(({name, notes, phone}) => `${name} (${phone}): ${notes}`).join(', ')
        : '';
    },
    getCustomerName(uid) {
      const c = this.getCustomer(uid);
      return `${c.first_name} ${c.last_name}`
    },
    formatAddress(a) {
      function formatPostcode(s) {
        return (s || '').replace(' ', '').toUpperCase();
      }

      return `${[
        (a.unit ? `${a.unit}-` : '') + a.line1,
        a.line2,
        // a.city,
        formatPostcode(a.postal_code)
      ]
        .filter(a => !!a)
        .join(', ')
      }`;
    },
    copyToClipboard(text) {
      try {
        navigator.clipboard.writeText(text);
      } catch (e) {
        alert('sorry, copy not supported on this device: ' + e.message)
      }
    },
    linkTask(uid, onfleetShortId) {
      const dates = this.getCustomerDeliveryDates(this.orders.filter(o => o.uid === uid));
      if (dates.length > 1) {
        alert('there was more than one customer delivery date: ' + dates.join(', '));
      }
      const [date] = dates;
      console.log('customer delivery dates', dates);
      const payload = {
        uid,
        date,
        onfleetShortCode: onfleetShortId
      };
      console.log('payload', payload);

      api
        .post('v2/onfleet/link', payload)
        .then(r => {
          console.log('done', r);
          alert('task has been linked.  eta and driver assignment will be updated in ~5 mins');
          this.showLinkDialog = false;
          this.onfleetUid = '';
          this.onfleetShortId = '';
        })
        .catch(e => console.error('fail', e));
    },
    filterByRack(orders, rack) {
      return orders.filter(o => (o.bin || '').split('-')[0] === rack);
    },
    async fetchOrders() {
      const params = {
        from: this.dateFrom,
        to: this.dateTo,
        productions: [this.production],
        // properties: ['quantity', 'allergyDetected', 'allergies', 'meal_id', 'stream', 'tod', 'meal_size'],
      };
      console.log('fetching ', params);
      const orders = await api
        .get('v2/order/search', {params})
        .then(({data}) => data);
      this.ordersFetched = orders;
      return orders;
    },
  },
  props: {
    isAdmin: {type: Boolean, default: false, required: false},
    isDelivery: {type: Boolean, default: false, required: false},
  }
}
</script>
